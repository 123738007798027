import { apiv1 } from "@/request/apiv1";
import qs from "qs";
// import axios from "axios";

let baseUrl = '/pages'


// 获取产品的分页列表
export function GetPagesList(params = { page: 0, size: 20 }) {
  return new Promise((resolve, reject) => {
    apiv1.get(`${baseUrl}?${qs.stringify(params, {
      arrayFormat: "repeat"
    })}`)
      .then(res => {
        if (res.status === 200 || res.status === 206) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

// 获取所有的部门数据树形列表
export function GetPagesTreeList(id = null) {
  return new Promise((resolve, reject) => {
    let path = `${baseUrl}/tree`
    if (id !== null && id !== '') {
      path = `${baseUrl}/tree/${id}`
    }
    apiv1.get(path)
      .then(res => {
        if (res.status === 200) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}


export function GetPagesById(id = null) {
  return new Promise((resolve, reject) => {
    apiv1.get(`${baseUrl}/${id}`)
      .then(res => {
        if (res.status === 200) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

export function GetPagesSectionsById(id = null) {
  return new Promise((resolve, reject) => {
    apiv1.get(`${baseUrl}/${id}/sections`)
      .then(res => {
        if (res.status === 200) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

export function GetPagesSectionsTypes() {
  return new Promise((resolve, reject) => {
    apiv1.get(`${baseUrl}/sections/types`)
      .then(res => {
        if (res.status === 200) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

export function PostPages(params = []) {
  return new Promise((resolve, reject) => {
    apiv1.post(`${baseUrl}`, params)
      .then(res => {
        if (res.status === 200 || res.status === 201) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

export function PutPagesById(id = null, params = []) {
  return new Promise((resolve, reject) => {
    apiv1.put(`${baseUrl}/${id}`, params)
      .then(res => {
        if (res.status === 200 || res.status === 201) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

export function DeletePagesByIds(ids = []) {
  return new Promise((resolve, reject) => {
    apiv1.delete(`${baseUrl}?ids=${ids}`)
      .then(res => {
        if (res.status === 204) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}


/**
 * 获取修改许可
 * @param {String,Number} id 
 */
export function GetUpdateCheck(id = null) {
  return new Promise((resolve, reject) => {
    apiv1.get(`${baseUrl}/${id}/can/update`)
      .then(res => {
        if (res.status === 200) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

/**
 * 获取删除许可
 * @param {String,Number} id 
 */
export function GetDeleteCheck(ids = []) {
  return new Promise((resolve, reject) => {
    apiv1.get(`${baseUrl}/can/delete?ids=${ids}`)
      .then(res => {
        if (res.status === 200) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

